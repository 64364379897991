import Journal from "../../articles/index.js";
import { graphql } from "gatsby"


export const pageQuery = graphql`
  query TestArticlesComponentQuery {
    pageMetadata(page_id: {eq: "articles"}) {
      content,
      page_id
    }
  }
`


export default Journal;
